import * as React from "react"
import classNames from "classnames"
import Page from '../components/Page'
import Container from '../components/Container'
import ContactForm from "../components/forms/ContactForm"


import { Map, Envelope } from "../assets/contact.svgs"

const ContactPage = () => {
  return (
    <Page className={classNames('contact page')}>
      <section className={classNames('hero hero-section')}>
        <Container>
          <div className={classNames('txt')}>
            <h1><strong>Get in Touch</strong></h1>
            <ContactForm />
          </div>
          <div>
            <address className="pb-10 px-4 pt-4">
              <div>
                <b>Greater Seattle, USA</b>
              </div>
              <div>
                <span><Map /></span>
                <span>10650 NE 9th place, Unit 1524, Bellevue, Washington- 98004<br />Tel: (206) 774-0808</span>
              </div>
              <div>
                <span><Envelope /></span>
                <span><a href="mailto:contact@loopr.ai">contact@loopr.ai</a></span>
              </div>
            </address>          
          </div>
        </Container>
      </section>
    </Page>
  )
}

export default ContactPage
