import * as React from 'react';

import HubspotForm from 'react-hubspot-form'
import axios from 'axios'
import validator from 'validator'

import { useState } from "react"



const ContactForm = () => {

  const apiBase = process.env.REACT_IMAGE_BASE_URL;


  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [confirm, setConfirm] = useState(false);

  const [emailerror, setEmailerror] = useState(false);
  const [companyerror, setCompanyerror] = useState(false);

  const handleEmailChange = event => {
    const target = event.target
    const value = target.value
    setEmail(value);
  }
  const handleCompanyChange = event => {
    const target = event.target
    const value = target.value
    setCompany(value);
  }

  const handleMessageChange = event => {
    const target = event.target
    const value = target.value
    setMessage(value);
  }

  const handleSubmit = event => {

    if (!(validator.isEmail(email))) {
      setEmailerror(true)
    } else {
      setEmailerror(false);
    }
    if (company.length < 3) {
      setCompanyerror(true);
    } else {
      setCompanyerror(false);
    }
    if (validator.isEmail(email) && (company.length >= 3)) {
      const apiUrl = `${apiBase}/enquiries`;
      axios.post(apiUrl, {
        email: email,
        company: company,
        message: message
      })
        .then(function (response) {
          console.log(response);
          if (response.status === 200) {
            setCompany('');
            setMessage('');
            setConfirm(true);
            setEmailerror(false);
            setCompanyerror(false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  
  return (
    <>

      <div className='w-100 pt-28 px-4'>
        <HubspotForm
          portalId='21210148'
          formId='01e6e6e1-34c7-4f40-ace1-9aea79407833'
          region="na1"
          onSubmit={() => console.log('Submit!')}
          onReady={(form) => console.log('Form ready!')}
          loading={<div>Loading...</div>}
          submitText="Send"
        />
      </div>
    </>
  )
}
export default ContactForm